import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
/**
 * The UserCacheService is a service that caches user profiles for a pre-determined amount of time
 * specified by the cacheTimeOut constant. It also handles ongoing requests to prevent multiple
 * simultaneous requests for the same user profile.
 */
@Injectable({
  providedIn: "root",
})
export class ProfileModalService {
  private subject = new Subject<any>();

  setName(name: string) {
    this.subject.next(name);
  }

  refreshModal(bool: boolean) {
    this.subject.next(bool);
  }

  onUpdate(): Observable<any> {
    return this.subject.asObservable();
  }
}
