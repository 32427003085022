<div
  #containerForm="ngForm"
  [formGroup]="userDetailsForm"
  name="userDetailsForm"
  class="userDetails"
>
  <app-loader *ngIf="isUserLoading()" text="Loading"></app-loader>
  <app-loader *ngIf="(savingInfo && !isLoading)" text="Saving"></app-loader>
  <div
    class="p-field firstNameDiv"
    [ngClass]="{
      'has-error': !f.firstName.valid && f.firstName.touched,
    }"
  >
    <span class="userInfoCategory">First Name</span><br />
    <input
      class="form-control ui-inputtext"
      formControlName="firstName"
      placeholder="{{ f.FirstName }}"
      maxlength="40"
      required
      data-testid="userfnameedit"
      (focus)="$event.target.select()"
    />
    <div *ngIf="f.firstName.touched">
      <div
        data-testid="firstNameError"
        class="help-block"
        *ngIf="!this.user.FirstName || !firstName?.valid"
      >
        Required field
      </div>
      <div class="help-block" *ngIf="firstName.errors?.maxlength">
        First Name is too long (must be 40 characters or less)
      </div>
    </div>
  </div>
  <div
    class="p-field lastNameDiv"
    [ngClass]="{
      'has-error': !f.lastName.valid && f.lastName.touched,
    }"
  >
    <span class="userInfoCategory">Last Name</span><br />
    <input
      class="form-control ui-inputtext"
      placeholder="{{ f.LastName }}"
      formControlName="lastName"
      maxlength="50"
      required
      data-testid="userlnameedit"
      (focus)="$event.target.select()"
    />
    <div *ngIf="f.lastName.touched">
      <div
        data-testid="lastNameError"
        class="help-block"
        *ngIf="!this.user.LastName || !lastName?.valid"
      >
        Required field
      </div>
      <div class="help-block" *ngIf="lastName.errors?.maxlength">
        Last Name is too long (must be 50 characters or less)
      </div>
    </div>
  </div>
  <div>
    <div class="p-b-sm">
      <span class="userInfoCategory">Phone Number </span>
      <div class="pair">
        <div class="left">
          <div [ngClass]="{ 'has-error': !f.countryCode.valid }">
            <app-select
              componentId="countryCode"
              [items]="countryCodesArray"
              text="CountryName"
              value="CountryCode"
              placeholder="Select a Country Code"
              defaultText="{{ user?.CountryCode }}"
              [frmComponent]="userDetailsForm"
              (changed)="setPhoneNumberRequired(); canSave()"
            ></app-select>
          </div>
        </div>
        <div allowedForSelf="true" class="right">
          <div
            [ngClass]="{
              'has-error':
                f.phoneNumber.invalid &&
                (containerForm.submitted || f.phoneNumber.touched),
            }"
          >
            <input
              class="form-control"
              id="phoneNumber"
              formControlName="phoneNumber"
              type="text"
              defaultText="{{ user?.PhoneNumber }}"
              [mask]="phoneNumberMask"
              data-testid="userDetailPhoneNumber"
              (blur)="canSave()"
            />
            <div *ngIf="containerForm.submitted || f.phoneNumber.touched">
              <div class="help-block" *ngIf="f.phoneNumber.errors?.required">
                Required field
              </div>
              <div
                class="help-block"
                *ngIf="f.phoneNumber.hasError('minlength')"
              >
                Minimum 10 digits required
              </div>
              <div class="help-block" *ngIf="f.phoneNumber.hasError('pattern')">
                This field cannot contain letters and special characters.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="p-field email"
      [ngClass]="{
        'has-error': !f.email.valid && email?.dirty,
      }"
    >
      <app-email-verfication
        [location]="'editUser'"
        [emailLabel]="'EMAIL ADDRESS'"
        [userDetailsForm]="userDetailsForm"
        [user]="user"
        [showStaticEmail]="showStaticEmail()"
        [inEditingMode]="!showStaticEmail()"
        [labelClass]="'emailLabel'"
        [profileId]="user?.UserID"
        (pendingValidationEvent)="setPendingEmailValidation($event)"
        (emailUnique)="checkEmailUnique($event)"
        data-testid="userDetailEmail"
      >
      </app-email-verfication>
    </div>
    <div class="pair">
      <div class="left">
        <span class="userInfoCategory">Organization </span>
        <input
          type="text"
          class="form-control"
          placeholder="{{ this.orgName }}"
          style="flex-grow: 1; width: 100%"
          ng-readonly="true"
          [readonly]="true"
        />
      </div>
      <div class="right">
        <span class="userInfoCategory">Role </span>
        <input
          type="text"
          class="form-control"
          placeholder="{{ this.userRole }}"
          style="flex-grow: 1; width: 100%"
          ng-readonly="true"
          [readonly]="true"
        />
      </div>
    </div>
    <br />

    <div class="p-b-sm"></div>
    <div class="pair">
      <div class="left">
        <span class="userInfoCategory">Support PIN Number </span>
        <app-icon
          source="rn-help"
          tooltip-placement="right"
          pTooltip="<div><b>Support PIN</b> is used when calling Rightworks to verify your account</div>"
          [escape]="false"
        ></app-icon
        ><br />
        <div
          [ngClass]="{
            'has-error': f.supportPIN.invalid && !isLoading,
          }"
        >
          <input
            id="supportPIN"
            formControlName="supportPIN"
            class="form-control"
            type="text"
            [mask]="supportPINMask"
            [required]="isPINRequired"
            placeholder="{{ user?.SupportPIN }}"
            data-testid="userDetailSupportPin"
            (blur)="canSave()"
          />
          <div>
            <div class="help-block" *ngIf="f.supportPIN.errors?.required && !isLoading">
              Required field
            </div>
            <div
              class="help-block"
              *ngIf="f.supportPIN.invalid && !f.supportPIN.errors?.required && !isLoading"
            >
              Support PIN must be 4 digits long
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="p-b-sm">
          <span class="userInfoCategory">XID </span>
          <app-icon
            source="rn-help"
            tooltip-placement="right"
            [pTooltip]="xidHelp"
            [escape]="false"
          ></app-icon
          ><br />
          <div
            [ngClass]="{
              'has-error':
                f.xid.invalid && (containerForm.submitted || f.xid.touched),
            }"
          >
            <input
              [readonly]="!canEditXID"
              ng-readonly=" {{ !canEditXID }}"
              class="form-control"
              id="xid"
              formControlName="xid"
              maxlength="50"
              type="text"
              data-testid="userDetailXid"
              (blur)="canSave()"
            />
            <div *ngIf="containerForm.submitted || f.xid.touched">
              <div class="help-block" *ngIf="f.xid.errors?.maxlength">
                XID is too long (must be 50 characters or less)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right"></div>
  </div>
  <div class="cancelSaveButtons">
    <app-button
      id="btnCancel"
      label="Cancel"
      buttonStyle="addUserTeritary"
      style="height: 42px"
      (clickActionEmitter)="this.close()"
    >
    </app-button>
    <app-button
      id="btnSubmit"
      label="Save"
      [disabled]="!this.canSave()"
      showProcessing="true"
      [processing]="savingInfo"
      buttonStyle="primary"
      style="height: 42px"
      (clickActionEmitter)="saveUserInfo()"
    >
    </app-button>
  </div>
</div>
