import { Pipe, PipeTransform } from "@angular/core";
import { GridActions } from "../../components/third-party-wrappers/grid/configuration/grid-action-button";
import { GridActionButtonColumnConfiguration } from "../../components/third-party-wrappers/grid/configuration/grid-action-button-column-configuration";
import { GridAffectedColumnConfiguration } from "../../components/third-party-wrappers/grid/configuration/grid-affected-column-configuration";
import { GridBooleanColumnConfiguration } from "../../components/third-party-wrappers/grid/configuration/grid-boolean-column-configuration";
import { GridCheckLimitColumnConfiguration } from "../../components/third-party-wrappers/grid/configuration/grid-check-limit-column-configuration";
import { GridColumnBaseConfiguration } from "../../components/third-party-wrappers/grid/configuration/grid-column-base-configuration";
import { GridConfiguration } from "../../components/third-party-wrappers/grid/configuration/grid-configuration";
import { GetDialogDataForColumnConfiguration } from "../../components/third-party-wrappers/grid/configuration/grid-dialog";
import { GridFormatDateColumnConfiguration } from "../../components/third-party-wrappers/grid/configuration/grid-format-date";
import { GridHtmlColumnConfiguration } from "../../components/third-party-wrappers/grid/configuration/grid-html-column-configuration";
import { GridHyperlinkColumnConfiguration } from "../../components/third-party-wrappers/grid/configuration/grid-hyperlink-column-configuration";
import { GridLinkButtonColumnConfiguration } from "../../components/third-party-wrappers/grid/configuration/grid-linkbutton-column-configuration";
import { GridMultiValueColumnConfiguration } from "../../components/third-party-wrappers/grid/configuration/grid-multi-value-custom-configuration";
import { EscapeCharacterPipe } from "../escapeCharacter/escape-character.pipe";
import { GridMenuColumnConfiguration } from "../../components/third-party-wrappers/grid/configuration/grid-menu-column-configuration";

@Pipe({
  name: "rnGrid",
})
export class RnGridPipe implements PipeTransform {
  constructor(private escapeCharacterPipe: EscapeCharacterPipe) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    if (args && args.length > 0) {
      const param = args[0];
      switch (param) {
        case "headercountclass": {
          const headerClass = value?.toString();
          return headerClass && headerClass !== ""
            ? headerClass
            : "default-header-count";
        }
        case "coltype": {
          const col = value as GridColumnBaseConfiguration;
          const rowData = args.length > 1 ? args[1] : null;
          return col ? col.TypeName(rowData) : "";
        }
        case "coltypenotchecktype":
          {
            const col = value as GridColumnBaseConfiguration;
            if (col) {
              const typeName = col.TypeName(null);
              return typeName !== "checkbox" && typeName !== "checkboxLimit";
            }
          }
          break;
        case "colcanbesorted":
          {
            const col = value as GridColumnBaseConfiguration;
            if (col) {
              return col.SortOrder !== undefined && col.SortOrder;
            }
          }
          break;

        case "rnselectalldisabled":
          {
            const col = value as GridCheckLimitColumnConfiguration;
            if (col) {
              return col.selectAllDisabled;
            }
          }
          break;
        case "rnSelectAllTooltip":
          {
            const col = value as GridCheckLimitColumnConfiguration;
            if (col) {
              if (col.SelectAllTooltip) {
                return col.SelectAllTooltip();
              }
              return "Select All";
            }
          }
          break;
        case "retrieveclassforrow":
          {
            const config = args[1] as GridConfiguration;
            if (config && value) {
              return config.RetrieveClassForRow(value);
            }
          }
          break;
        case "retrievecolspanforcell":
          {
            const col = value as GridColumnBaseConfiguration;
            const config = args[1] as GridConfiguration;
            const rowData = args[2];
            if (col && config && rowData) {
              return config.RetrieveColSpanForCell(col, rowData);
            }
          }
          break;
        case "retrieveclassforcell":
          {
            const col = value as GridColumnBaseConfiguration;
            const config = args[1] as GridConfiguration;
            const rowData = args[2];
            if (col && config && rowData) {
              return config.RetrieveClassForCell(col, rowData);
            }
          }
          break;
        case "gettooltipforcell":
          {
            const col = value as GridColumnBaseConfiguration;
            const rowData = args[1];
            if (col && rowData) {
              if (col.Tooltip) {
                return col.Tooltip(rowData);
              }
              return "";
            }
          }
          break;
        case "getlinkbuttonclassforcell":
          {
            const linkCol = value as GridLinkButtonColumnConfiguration;
            if (linkCol && linkCol.Icon && linkCol.Icon !== "") {
              return `fa ${linkCol.Icon}`;
            }

            return "";
          }
          break;
        case "isfirstappicon":
          {
            const linkCol = value as GridMenuColumnConfiguration;
            if (linkCol && linkCol.isFirstAppIcon) {
              return true;
            }
          }
          break;
        case "firstappiconsource": {
          const linkCol = value as GridMenuColumnConfiguration;
          if (
            linkCol &&
            linkCol.firstAppiconSource &&
            linkCol.firstAppiconSource != ""
          ) {
            return linkCol.firstAppiconSource;
          }
          return "";
        }
        case "firstmenutext": {
          const linkCol = value as GridMenuColumnConfiguration;
          if (linkCol && linkCol.firstMenuText && linkCol.firstMenuText != "") {
            return linkCol.firstMenuText;
          }
          return "";
        }
        case "firstappiconclass": {
          const linkCol = value as GridMenuColumnConfiguration;
          if (
            linkCol &&
            linkCol.firstAppIconClass &&
            linkCol.firstAppIconClass != ""
          ) {
            return linkCol.firstAppIconClass;
          }
          return "";
        }
        case "getremovebuttonclassforcell": {
          const linkCol = value as GridLinkButtonColumnConfiguration;
          if (linkCol && linkCol.removeClass && linkCol.removeClass !== "") {
            return `${linkCol.removeClass}`;
          }

          return "";
        }
        case "getlinkbuttontextdataforcolumn":
          {
            const linkCol = value as GridLinkButtonColumnConfiguration;
            if (args.length > 1) {
              const rowData = args[1];
              if (linkCol && rowData) {
                return linkCol.GetLinkText(rowData);
              }
              return "";
            }
          }
          break;
        case "gethyperlinkenabled":
          {
            const linkCol = value as GridHyperlinkColumnConfiguration;
            if (args.length > 1) {
              const rowData = args[1];
              if (linkCol && rowData) {
                return linkCol.GetLinkIsEnabled(rowData);
              }
              return true;
            }
          }
          break;
        case "gethyperlinkashref":
          {
            const linkCol = value as GridHyperlinkColumnConfiguration;
            if (args.length > 1) {
              const rowData = args[1];
              if (linkCol && rowData) {
                return !linkCol.GetClickAvailable(rowData);
              }
              return true;
            }
          }
          break;
        case "gethyperlinkhrefdataforcolumn":
          {
            const linkCol = value as GridHyperlinkColumnConfiguration;
            if (args.length > 1) {
              const rowData = args[1];
              if (linkCol && rowData) {
                return linkCol.GetHref(rowData);
              }
              return "";
            }
          }
          break;
        case "gethyperlinktextdataforcolumn":
          {
            const linkCol = value as GridHyperlinkColumnConfiguration;
            if (args.length > 1) {
              const rowData = args[1];
              if (linkCol && rowData) {
                return linkCol.GetLinkText(rowData);
              }
              return "";
            }
          }
          break;
        case "getaffecteddataforcolumn":
          {
            const col = value as GridAffectedColumnConfiguration;
            if (args.length > 1) {
              const rowData = args[1];
              if (col && rowData) {
                let htmlString = "";
                htmlString = col.getHtml(rowData);
                return htmlString;
              }
              return "";
            }
          }
          break;
        case "gethtmldataforcolumn":
          {
            const col = value as GridHtmlColumnConfiguration;
            if (args.length > 1) {
              const rowData = args[1];
              if (col && rowData) {
                let htmlString = "";
                htmlString = col.GetHtmlDataForColumn(rowData);
                return htmlString;
              }
              return "";
            }
          }
          break;
        case "getbooleandataforcolumn":
          {
            const col = value as GridBooleanColumnConfiguration;
            if (args.length > 1) {
              const rowData = args[1];
              if (col && rowData) {
                return col.CustomDataRetrieval(rowData) == "true"
                  ? col.trueValue
                  : col.falseValue;
              }
            }
          }
          break;
        case "isbooleanvaluefalsey":
          {
            const col = value as GridBooleanColumnConfiguration;
            if (args.length > 1) {
              const rowData = args[1];
              if (col && rowData) {
                return (
                  col.falseyButtonText &&
                  col.CustomDataRetrieval(rowData) !== "true"
                );
              }
            }
          }
          break;
        case "getdialogvaluedataforcolumn":
          {
            const col = value as GetDialogDataForColumnConfiguration;
            if (args.length > 1) {
              const rowData = args[1];
              if (col && rowData) {
                return col.GetDialogString(rowData);
              }
              return "";
            }
          }
          break;
        case "getdataforcell":
          {
            const col = value as GetDialogDataForColumnConfiguration;
            if (args.length > 1) {
              const config = args[1] as GridConfiguration;
              const rowData = args[2];
              if (col && config && rowData) {
                return config.getDataForCell(col, rowData);
              }
            }
          }
          break;
        case "getdatevaluedataforcolumn":
          {
            const col = value as GridFormatDateColumnConfiguration;
            if (args.length > 1) {
              const rowData = args[1];
              if (col && rowData) {
                return col.GetDateString(rowData);
              }
              return "";
            }
          }
          break;
        case "getmultivaluedataforcolumn":
          {
            const col = value as GridMultiValueColumnConfiguration;
            if (args.length > 1) {
              const rowData: any = args[1];
              if (col && rowData) {
                let htmlString = "";
                // check removed records to set loading on row
                const storedString = sessionStorage.getItem("removedUserIDs");
                const retrievedArray = JSON.parse(storedString) as string[];

                if (retrievedArray?.includes(rowData?.UserID.toString())) {
                  htmlString += `
                  <div class="dspy-flex"><div class="m-bt-auto m-l-neg8 addUserSpinner pull-left">
                    <i class="fa-solid fa-circle-notch fa-spin faMedium fa-fw"></i>
                  </div>
                `;
                }

                htmlString += col.isLink
                  ? "<a class='rn-text-link' href='" +
                    col.GetHref(rowData) +
                    "'>"
                  : "";
                for (let c = 0; c < col.fieldNames.length; c++) {
                  const transformedColData: string =
                    this.escapeCharacterPipe.transform(
                      rowData[col.fieldNames[c]],
                    );
                  htmlString +=
                    "<span class='" +
                    col.classNames[c] +
                    "'>" +
                    transformedColData;
                  +"</span>";
                }
                htmlString += col.isLink ? "</a>" : "";
                return htmlString;
              }
            }
          }
          break;
        case "isitemdisabled":
          {
            const col = value as GridMultiValueColumnConfiguration;
            if (args.length > 1) {
              const rowData = args[1];
              if (col && rowData && col.GetIsDisabled) {
                return col.GetIsDisabled(rowData);
              }
              return false;
            }
          }
          break;
        case "getactionsforcolumn":
          {
            const col = value as GridActionButtonColumnConfiguration;
            if (args.length > 1) {
              const rowData = args[1];
              const actions = col.buttonConfig.find(
                (b) => b.property == rowData,
              );
              if (!actions) {
                return new Array<GridActions>();
              } else {
                return actions.buttonsText;
              }
            }
          }
          break;
        case "getpredicatevalue": {
          const rowData = value;
          if (args.length > 1) {
            const col = args[1] as GridActionButtonColumnConfiguration;
            if (col && rowData) {
              return col.predicate(rowData);
            }
          }
        }
      }
    }
    return null;
  }
}
