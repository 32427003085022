<div [ngClass]="StyleClass">
  <div [ngClass]="DialogHeaderClass">
    <h3 [ngClass]="HeaderClass">{{ Title }}</h3>
    <div
      tabindex="0"
      (keyup.enter)="CloseClick()"
      role="presentation"
      class="close-button"
      (click)="CloseClick()"
      *ngIf="ShowCloseButton"
    >
      <i class="fa-solid fa-times"></i>
    </div>
    <div
      tabindex="0"
      (keyup.enter)="CloseFromBodyClick()"
      class="close-clicker dspy-none"
      (click)="CloseFromBodyClick()"
    ></div>
  </div>
  <div class="modal-body" [ngClass]="DialogBodyClass">
    <div
      data-testid="orgMFAconfirmMsgContainer"
      *ngIf="MessageContainsHTML"
      [innerHtml]="Message | safeHtml"
    ></div>
    <div *ngIf="!MessageContainsHTML">
      {{ Message }}
    </div>
  </div>
  <div class="modal-footer" [ngClass]="DialogFooterClass">
    <hr class="modal-footer-seperator" />
    <div class="flex-row no-bottom-margin">
      <div [ngClass]="DialogFooterCancelClass">
        <app-button
          id="btnCancel1"
          [label]="CancelButtonText"
          (clickActionEmitter)="CancelClick()"
          noContentPadding="cancelButtonNoContentPadding"
          *ngIf="!UseButtonForCancel && !HideCancel"
          buttonStyle="{{ CancelButtonStyleClass || 'addUserTeritary' }}"
        >
        </app-button>
        <app-button
          id="btnCancel2"
          label="{{ CancelButtonText }}"
          *ngIf="UseButtonForCancel && !HideCancel"
          (clickActionEmitter)="CancelClick()"
          noContentPadding="cancelButtonNoContentPadding"
          buttonStyle="secondary"
        >
        </app-button>
      </div>
      &nbsp;
      <div [ngClass]="DialogFooterConfirmClass">
        <app-button
          id="btnConfirm1"
          showIcon="{{ showConfirmIcon }}"
          imgSource="{{ showConfirmIconImageSource }}"
          imgClass="{{ confirmImageIconClass }}"
          label="{{ ConfirmButtonText }}"
          (clickActionEmitter)="ConfirmClick()"
          noContentPadding="confirmButtonNoContentPadding"
          [processing]="IsWorking"
          showProcessing="true"
          buttonStyle="{{ ConfirmButtonStyleClass }}"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>
